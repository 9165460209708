.shortlist {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #f09819;
  background: rgba(240, 152, 25, 0.2);
  border: 1px solid #f09819;
  border-radius: 4px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #31bf6d;
  background: rgba(49, 191, 109, 0.22);
  border: 1px solid #31bf6d;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
